<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-card>
      <div class="mb-3 w-100 d-flex align-items-center">
        <h1>تنظیمات سئوی بلاگ ها</h1>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            class="position-relative"
            :items="blogsList"
            responsive
            :fields="myTableColumns"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="موردی موجود نیست!"
          >
            <template #cell(actions)="data">
              <div class="d-flex align-items-start" style="gap: 2rem">
                <b-link
                  :to="{
                    name: 'pages-seo-blogs-single',
                    params: { id: data.item.blogId },
                  }"
                >
                  <feather-icon
                    size="20"
                    icon="SettingsIcon"
                    class="text-primary"
                  />
                </b-link>
              </div>
            </template>
          </b-table>
        </b-col>
        <b-col cols="12">
          <!-- pagination -->
          <div class="my-2 d-flex align-items-center justify-content-center">
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalCount"
              :per-page="count"
              first-number
              align="center"
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  style="transform: rotate(180deg)"
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
export default {
  title: "تنظیمات سئوی بلاگ ها",
  mounted() {
    this.getAllBlogsList();
  },
  watch: {
    pageNumber: {
      handler(val) {
        this.getAllBlogsList();
      },
    },
  },
  data() {
    return {
      isLoading: false,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      categoryName: null,
      tagId: null,
      searchByTitle: null,
      date: null,
      blogsList: null,
      blogStatuses: {
        1: "منتشر شده",
        2: "آرشیو",
      },
      myTableColumns: [
        {
          key: "blogId",
          label: "شناسه بلاگ",
        },
        {
          key: "title",
          label: "عنوان بلاگ",
        },
        {
          key: "status",
          label: "وضعیت",
        },
        {
          key: "userInfo.fullName",
          label: "نویسنده",
        },
        {
          key: "actions",
          label: "تنظیم سئو",
        },
      ],
      tempBlog: null,
    };
  },
  methods: {
    async getAllBlogsList() {
      try {
        this.isLoading = true;
        let _this = this;
        let getAllBlogs = new GetAllBlogs(_this);
        let qParams = {
          count: _this.count,
          pageNumber: _this.pageNumber,
        };
        if (this.categoryName) {
          qParams["categoryName"] = this.categoryName;
        }
        if (this.tagId) {
          qParams["tagId"] = this.tagId;
        }
        if (this.searchByTitle) {
          qParams["searchByTitle"] = this.searchByTitle;
        }
        if (this.date) {
          qParams["date"] = this.date;
        }
        getAllBlogs.setRequestParam(qParams);
        await getAllBlogs.fetch((response) => {
          if (response.isSuccess) {
            _this.blogsList = response.data.blogs;
            _this.totalCount = response.data.blogsCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    BContainer,
    BCard,
    BRow,
    BFormRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BButton,
    BTable,
    BImg,
    BMedia,
    BAvatar,
    BModal,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
  },
};
import {
  BContainer,
  BCard,
  BRow,
  BFormRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BButton,
  BTable,
  BImg,
  BMedia,
  BAvatar,
  BModal,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BOverlay,
} from "bootstrap-vue";
import { GetAllBlogs } from "@/libs/Api/Blog";
</script>
